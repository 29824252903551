import React from "react";
import "./ListItem.css";

const ListItem = ({ img, alt, header, text, cl }) => {
  return (
    <li className="aufan__list-item">
      <img src={img} alt={alt} tile={alt} className="aufan__list-img" />
      <div className="aufan__overlay">
        <p className={`aufan__small-header t-28 col-white ${cl}`}>{header}</p>
        <p className={`aufan__small-text t-18 col-white ${cl}`}>{text}</p>
      </div>
    </li>
  );
};

export default ListItem;
