import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Reviews.css";
import stars from "../../images/stars.svg";
import rev1 from "../../images/John-K.webp";
import rev2 from "../../images/Emily-H.webp";
import rev3 from "../../images/Luke-t.webp";
import rev4 from "../../images/Sophia-S.webp";
import rev5 from "../../images/Benjamin-B.webp";

const Reviews = () => {
  return (
    <section className="uafan-reviews mg-top pad">
      <h2 className="uafan-reviews__header">Our customers' opinions</h2>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
        }}
        navigation
        pagination={{ clickable: true }}
        className="uafan-swiper"
      >
        <SwiperSlide className="uafan-review__slide">
          <img
            src={rev1}
            alt="John K."
            title="John K."
            width="120"
            height="120"
          />
          <p className="t-28">John K.</p>
          <img src={stars} alt="stars" title="stars" />
          <p className="t-18 col-grey">
            Aufantastictop provided exceptional service and equipment. All our
            events ran smoothly with their team.
          </p>
        </SwiperSlide>
        <SwiperSlide className="uafan-review__slide">
          <img
            src={rev2}
            alt="Emily H."
            title="Emily H."
            width="120"
            height="120"
          />
          <p className="t-28">Emily H.</p>
          <img src={stars} alt="stars" title="stars" />
          <p className="t-18 col-grey">
            The casino tables were of excellent quality, and the staff were
            extremely professional. All our guests were satisfied!
          </p>
        </SwiperSlide>
        <SwiperSlide className="uafan-review__slide">
          <img
            src={rev3}
            alt="Luke T."
            title="Luke T."
            width="120"
            height="120"
          />
          <p className="t-28">Luke T.</p>
          <img src={stars} alt="stars" title="stars" />
          <p className="t-18 col-grey">
            We've been working together for years, and we always receive the
            best equipment. We're maximally satisfied!
          </p>
        </SwiperSlide>
        <SwiperSlide className="uafan-review__slide">
          <img
            src={rev4}
            alt="Sophia S."
            title="Sophia S."
            width="120"
            height="120"
          />
          <p className="t-28">Sophia S.</p>
          <img src={stars} alt="stars" title="stars" />
          <p className="t-18 col-grey">
            The staff were helpful and courteous, and the equipment was
            top-notch. The event was a huge success
          </p>
        </SwiperSlide>
        <SwiperSlide className="uafan-review__slide">
          <img
            src={rev5}
            alt="Benjamin B."
            title="Benjamin B."
            width="120"
            height="120"
          />
          <p className="t-28">Benjamin B.</p>
          <img src={stars} alt="stars" title="stars" />
          <p className="t-18 col-grey">
            The aufantastictop team met all our expectations, and the quality of
            the casino equipment exceeded our expectations.
          </p>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Reviews;
