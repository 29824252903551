import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import Popup from "../Popup/Popup";

function App() {
  return (
    <div className="aufan-page">
      <Header />
      <Popup/>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
