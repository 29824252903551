import React from 'react';
import './WhoAreWe.css'
import ListItem from "../ListItem/ListItem";
import img1 from "../../images/about-1.webp";
import img2 from "../../images/about-2.webp";
import img3 from "../../images/about-3.webp";

const WhoAreWe = () => {
  return (
    <section className="aufan-who pad">
        <h2 className="aufan-who__header mg-top">Who are we?</h2>
        <p className="aufan-why__text t-22">
          Welcome to the leading supplier of slot decks, quality casino
          equipment. We offer unparalleled quality and service for your success!
        </p>
        <ul className="aufan-why__list">
          <ListItem
            img={img1}
            alt="table rent"
            header="Table rental"
            text="We offer premium quality casino tables for rent for various games such as poker, roulette, and blackjack. All of our tables are of high standard and have an elegant appearance, making them ideal for any event"
            cl="text-center"
          />
          <ListItem
            img={img2}
            alt="Professional dealers"
            header="Professional dealers"
            text="We provide experienced and skilled dealers for every casino event, ensuring professional game management and guest entertainment. We pay attention to the highest professional standards and impeccable appearance"
            cl="text-center"
          />
          <ListItem
            img={img3}
            alt="Equipment manufacturing"
            header="Equipment manufacturing"
            text="Our company manufactures its own casino equipment, meeting the strictest quality and safety standards. We take pride in our products made with modern technology, ensuring durability and reliability."
            cl="text-center"
          />
        </ul>
      </section>
  )
}

export default WhoAreWe