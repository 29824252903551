import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";

const Footer = () => {
  return (
    <footer id="contacts" className="aufan-footer mg-top pad">
      <Link to="/" className="aufan-footer__link">
        <img
          src={logo}
          alt="footer logo"
          title="footer logo"
          width="164"
          height="51"
        /></Link>
        <div className="aufan-footer__text-container">
          <p className="t-18 col-white aufan-footer__text">
            "au-fantastic-top.com" - uses cookies for maximum convenience. By
            staying on the site, you accept the use of cookies. 
            <Link to="/cookies" className="aufan-footer__cookie-link pc">
              Read more
            </Link>
          </p>
          <Link to="/cookies" className="aufan-footer__cookie-link t-18 mob">
              Read more
            </Link>
          <div className="aufan-footer__link-container">
        <Link to="/" className="aufan-footer__link">
          Home
        </Link>
        <Link to="/privacy" className="aufan-footer__link">
          Privacy policy
        </Link>
        <Link
          to="https://maps.app.goo.gl/xrkaVN49pfXTXRtx6"
          target="_blank"
          className="aufan-footer__link"
        >
          Map
        </Link>
        <Link
          to="mailto:au-fantastic-top@gmail.com"
          className="aufan-footer__link"
        >
          Contact
        </Link>
        <p className="aufan-header__age">18+</p>
      </div>
        </div>
      
    </footer>
  );
};

export default Footer;
