import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import logo from "../../images/logo.svg";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  const handleLinkClick = () => {
    setIsActive(false);
  };

  return (
    <div className="aufan-header__container pad">
      <Link to="/">
        <img src={logo} alt="logo" title="logo" width="164" height="51" />
      </Link>
      <div className="aufan-header__link-container">
        <Link to="/" className="aufan-header__link">
          Home
        </Link>
        <Link to="/privacy" className="aufan-header__link">
          Privacy policy
        </Link>
        <Link
          to="https://maps.app.goo.gl/xrkaVN49pfXTXRtx6"
          target="_blank"
          className="aufan-header__link"
        >
          Map
        </Link>
        <Link
          to="mailto:au-fantastic-top@gmail.com"
          className="aufan-header__link"
        >
          Contact
        </Link>
        <p className="aufan-header__age">18+</p>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="aufan-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={() => setIsActive(false)}
          />
          <ul className="burger-menu__nav-container">
            <li className="burger-menu__nav-link">
              <Link
                to="/"
                className="aufan-header__link"
                onClick={handleLinkClick}
              >
                Home
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/privacy"
                className="aufan-header__link"
                onClick={handleLinkClick}
              >
                Privacy policy
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="https://maps.app.goo.gl/xrkaVN49pfXTXRtx6"
                className="aufan-header__link"
                onClick={handleLinkClick}
              >
                Map
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="mailto:au-fantastic-top@gmail.com"
                className="aufan-header__link"
                onClick={handleLinkClick}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
