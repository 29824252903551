import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="aufan-popup-cookie">
        <form className="aufan-popup__form">
          <p className="aufan-popup__header-cookie t-24">Cookies and privacy</p>
          <p className="aufan-popup__text t-15">
            We use cookies to improve your experience and analyze site traffic.
            If you continue browsing, we will assume that you accept its use.
            For more information
          </p>
          <div className="aufan-popup__btn-container">
            <a href="/cookies" className="aufan-popup__btn-cookie">
              More information
            </a>
            <p
              className="aufan-popup__btn-cookie aufan-popup__btn-agree col-white"
              onClick={addPopupCookie}
            >
              Accept
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
