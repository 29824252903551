import React from "react";
import "./Advantages.css";
import adv1 from "../../images/Premium-Equipment-icon.svg";
import adv2 from "../../images/Customisation-Options-icon.svg";
import adv3 from "../../images/Exceptional-Service-icon.svg";
import adv4 from "../../images/Expert-Knowledge-icon.svg";
import adv5 from "../../images/Decades-of-Experience-icon.svg";
import adv6 from "../../images/Customer-Satisfaction-icon.svg";
import { Link } from "react-router-dom";

const Advantages = () => {
  return (
    <section className="aufan-advantages mg-top pad">
      <h2 className="aufan-advantages__header">our advanatges</h2>
      <ul className="aufan-advantages__list">
        <li className="aufan-advantages__item">
          <img
            src={adv1}
            alt="Premium Equipment"
            title="Premium Equipment"
            width="80"
            height="80"
          />
          <p className="t-28 text-center">Premium Equipment</p>
          <p className="t-18 col-grey text-center">
            We offer only the highest quality casino equipment, ensuring
            long-lasting performance and reliability.
          </p>
        </li>
        <li className="aufan-advantages__item">
          <img
            src={adv2}
            alt="Customisation Options"
            title="Customisation Options"
            width="80"
            height="80"
          />
          <p className="t-28 text-center">Customisation Options</p>
          <p className="t-18 col-grey text-center">
            Our equipment can be tailored to meet your specific event needs,
            ensuring a perfect fit.
          </p>
        </li>
        <li className="aufan-advantages__item">
          <img
            src={adv3}
            alt="Exceptional Service"
            title="Exceptional Service"
            width="80"
            height="80"
          />
          <p className="t-28 text-center">Exceptional Service</p>
          <p className="t-18 col-grey text-center">
            Our team is always ready to provide seamless and professional
            service at your events.
          </p>
        </li>
        <li className="aufan-advantages__item">
          <img
            src={adv4}
            alt="Expert Knowledge"
            title="Expert Knowledge"
            width="80"
            height="80"
          />
          <p className="t-28 text-center">Expert Knowledge</p>
          <p className="t-18 col-grey text-center">
            With years of experience, we provide expert advice to our clients on
            the best casino equipment and services.
          </p>
        </li>
        <li className="aufan-advantages__item">
          <img
            src={adv5}
            alt="Decades of Experience"
            title="Decades of Experience"
            width="80"
            height="80"
          />
          <p className="t-28 text-center">Decades of Experience</p>
          <p className="t-18 col-grey text-center">
            Our decades-long experience in the casino industry has made us a key
            factor in our clients' success.
          </p>
        </li>
        <li className="aufan-advantages__item">
          <img
            src={adv6}
            alt="Customer Satisfaction"
            title="Customer Satisfaction"
            width="80"
            height="80"
          />
          <p className="t-28 text-center">Customer Satisfaction</p>
          <p className="t-18 col-grey text-center">
            Customer satisfaction is always our top priority, and we strive to
            exceed their expectations in every way.
          </p>
        </li>
      </ul>
      <p className="t-28 aufan-advantages__small-header text-center">Get in Touch</p>
      <p className="t-22 col-grey text-center">
        If you're interested in learning more about Aufantastictop and how we
        can help take your casino to the next level, we'd love to hear from you.
        Contact us today to discover how our equipment can enhance your players'
        experience and drive business growth.
      </p>
      <Link to="/contact" className="aufan-advantages__link col-white t-24">Contact</Link>
    </section>
  );
};

export default Advantages;
