import React, { useState } from "react";
import "./Contact.css";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("mail.php", formData)
      .then((response) => {
        console.log("Message send");
      })
      .catch((error) => {
        console.log("Error sending message");
      });
  };

  return (
    <section className="aufan-contact pad">
      <h1 className="aufan-contact__header col-white">Come to us</h1>
      <p className="t-24 col-white aufan-contact__text">
        Contact us today and start creating the perfect casino atmosphere that
        will impress your guests.
      </p>
      <form onSubmit={handleSubmit} className="aufan-contact__form">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your name"
          className="aufan-form__input t-18"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your emil address"
          className="aufan-form__input t-18"
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your message"
          className="aufan-form__input aufan-form__text t-18"
          rows={5}
        />
        <button type="submit" className="aufan-form__btn t-24 col-white">
          Send
        </button>
      </form>
    </section>
  );
};

export default Contact;
