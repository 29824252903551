import React from "react";
import "./Main.css";
import { Link } from "react-router-dom";
import WhoAreWe from "../WhoAreWe/WhoAreWe";
import About from "../About/About";
import Services from "../Services/Services";
import Advantages from "../Advantages/Advantages";
import Examples from "../Examples/Examples";
import Reviews from "../Reviews/Reviews";
import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="aufan-main">
      {/* <MainPopup/> */}
      <section className="aufan-main__cover">
        <h1 className="aufan-main__header col-white">Casino equipment</h1>
        <p className="aufan-main__text t-24 col-white">
          Here you will find the best, legal and licensed casino equipment that
          guarantees safety and efficiency at every event. Choose from the best
          and experience premium quality!
        </p>
        <div className="aufan-main__btn-container">
          <Link
            to="/about-us"
            className="aufan-main__link about-link col-white t-24"
          >
            About us
          </Link>
          <Link
            to="mailto:au-fantastic-top@gmail.com"
            className="aufan-main__link contact-link t-24"
          >
            Contact
          </Link>
        </div>
      </section>
      <WhoAreWe/>
      <About/>
      <Services/>
      <Advantages/>
      <Examples/>
      <Reviews/>
    </main>
  );
};

export default MainCover;
