import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Services.css";
import ListItem from "../ListItem/ListItem";
import img1 from "../../images/service-1.webp";
import img2 from "../../images/service-2.webp";
import img3 from "../../images/service-3.webp";
import img4 from "../../images/service-4.webp";

const Services = () => {
  return (
    <section className="aufan-services pad mg-top">
      <h2 className="aufan-services__header">Our services</h2>
      <ul className="aufan-services__list pc">
        <ListItem
          img={img1}
          alt="Premium Casino Table Rental"
          header="Premium Casino Table Rental"
          text="Experience the ultimate in gaming luxury with our premium casino tables, designed to elevate your event to the next level."
          cl=""
        />
        <ListItem
          img={img2}
          alt="High-Quality Gaming Accessories"
          header="High-Quality Gaming Accessories"
          text="Take your gaming experience to new heights with our range of high-quality accessories, designed to enhance gameplay and provide an unparalleled level of excitement."
          cl=""
        />
        <ListItem
          img={img3}
          alt="Casino Equipment Manufacturing"
          header="Casino Equipment Manufacturing"
          text="We design and manufacture casino equipment that meets the highest standards of quality, reliability, and innovation, ensuring an unforgettable gaming experience for your players."
          cl=""
        />
        <ListItem
          img={img4}
          alt="Professional Event Staffing"
          header="Professional Event Staffing"
          text="Our team of experienced professionals will ensure that your event runs smoothly and efficiently, providing exceptional service and attention to detail to make your event a success."
          cl=""
        />
      </ul>

      <Swiper
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="services__swiper mob"
      >
        <SwiperSlide className="slide">
          <ListItem
            img={img1}
            alt="Premium Casino Table Rental"
            header="Premium Casino Table Rental"
            text="Experience the ultimate in gaming luxury with our premium casino tables, designed to elevate your event to the next level."
            cl=""
          />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <ListItem
            img={img2}
            alt="High-Quality Gaming Accessories"
            header="High-Quality Gaming Accessories"
            text="Take your gaming experience to new heights with our range of high-quality accessories, designed to enhance gameplay and provide an unparalleled level of excitement."
            cl=""
          />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <ListItem
            img={img3}
            alt="Casino Equipment Manufacturing"
            header="Casino Equipment Manufacturing"
            text="We design and manufacture casino equipment that meets the highest standards of quality, reliability, and innovation, ensuring an unforgettable gaming experience for your players."
            cl=""
          />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <ListItem
            img={img4}
            alt="Professional Event Staffing"
            header="Professional Event Staffing"
            text="Our team of experienced professionals will ensure that your event runs smoothly and efficiently, providing exceptional service and attention to detail to make your event a success."
            cl=""
          />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Services;
