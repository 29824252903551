import React from "react";
import "./About.css";
import logo from "../../images/logo-big.svg";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();

  return (
    <section className="aufan-about mg-top pad">
      {location.pathname === "/about-us" ? (
        <h1 className="aufan-about__header">About us</h1>
      ) : (
        <h2 className="aufan-about__header">About us</h2>
      )}
      <p className="aufan-about__text t-22 col-grey">
        At Aufantastictop, we're passionate about delivering exceptional gaming
        experiences to Australian casinos. With a commitment to excellence and a
        focus on innovation, we provide top-notch casino equipment that sets the
        standard for the industry.
      </p>
      <div className="aufan-about__text-container">
        <div className="aufan-about__section">
          <p className="t-28 aufan-about__small-header">our story</p>
          <p className="t-18 col-grey">
            Founded by a team of gaming enthusiasts, Aufantastictop has grown to
            become a leading supplier of casino equipment in Australia. Our
            journey began with a simple yet ambitious goal: to create gaming
            solutions that would exceed our customers' expectations. Today,
            we're proud to have partnered with some of the most prestigious
            casinos in the country, providing them with cutting-edge equipment
            that enhances their players' experience.
          </p>
        </div>
        <div className="aufan-about__section">
          <p className="t-28 aufan-about__small-header">our mission</p>
          <p className="t-18 col-grey">
            Our mission is to empower Australian casinos to deliver
            unforgettable gaming experiences. We achieve this by designing and
            manufacturing equipment that is not only reliable and efficient but
            also visually stunning. Our team of experts works tirelessly to stay
            ahead of the curve, incorporating the latest technology and trends
            into our products.
          </p>
        </div>
      </div>
      <img src={logo} alt="logo" title="logo" className="aufan-about__logo" />
      <p className="aufan-about__text t-22 col-grey">
        Our team is comprised of experienced professionals who share a passion
        for gaming and a commitment to excellence. With a deep understanding of
        the Australian casino market, we're well-equipped to provide tailored
        solutions that meet the unique needs of our customers.
      </p>
    </section>
  );
};

export default About;
