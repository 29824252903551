import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Examples.css";
import ex1 from "../../images/example-1.webp";
import ex2 from "../../images/example-2.webp";
import ex3 from "../../images/example-3.webp";
import ex4 from "../../images/example-4.webp";
import ex5 from "../../images/example-5.webp";
import ex6 from "../../images/example-6.webp";

const Examples = () => {
  return (
    <section className="aufan-examples mg-top pad">
      <h2 className="aufan-examples__header">examples of our work</h2>
      <div className="aufan-examples__flex pc">
        <div className="aufan-example__img1">
          <img src={ex1} alt="casino one" title="casino one" />
        </div>
        <div className="aufan-example__img2">
          <img src={ex2} alt="casino two" title="casino two" />
        </div>
        <div className="aufan-example__img3">
          <img src={ex3} alt="casino three" title="casino three" />
        </div>
      </div>
      <div className="aufan-examples__flex pc">
        <div className="aufan-example__img4">
          <img src={ex4} alt="casino four" title="casino four" />
        </div>
        <div className="aufan-example__img5">
          <img src={ex5} alt="casino five" title="casino five" />
        </div>
        <div className="aufan-example__img6">
          <img src={ex6} alt="casino six" title="casino six" />
        </div>
      </div>

      <Swiper
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
        className="examples__swiper mob"
      >
        <SwiperSlide className="slide">
          <img src={ex1} alt="casino one" title="casino one" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={ex2} alt="casino two" title="casino two" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={ex3} alt="casino three" title="casino three" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={ex4} alt="casino four" title="casino four" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={ex5} alt="casino five" title="casino five" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={ex6} alt="casino six" title="casino six" />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Examples;
